<template>
  <section>
    <span v-if="label" class="ui-input-label">{{ label }}</span>
    <v-text-field
      :rules="rules"
      :placeholder="placeholder"
      class="ui-input-field"
      rounded
      outlined
      height="38"
      :hide-details="hideDetail ? 'auto' : false"
      v-model="currentValue"
      :maxlength="max"
      :pattern="type == 'number' ? '\d*' : ''"
      :type="type"
      @keypress="(value) => $emit('keypress', value)"
      :error-messages="errorMessage"
      @blur="(value) => $emit('blur', value)"
      :disabled="disabled"
    ></v-text-field>
  </section>
</template>

<script>
export default {
  props: [
    "rules",
    "value",
    "label",
    "placeholder",
    "hideDetail",
    "type",
    "max",
    "pattern",
    "errorMessage",
    "disabled",
  ],
  data: () => ({
    currentValue: "",
  }),
  created: function () {
    this.currentValue = this.value || "";
  },
  watch: {
    currentValue: function (value) {
      this.$emit("onChange", this.currentValue);
    },
    value: function (value) {
      if (value === this.currentValue) return;
      this.currentValue = value;
    },
  },
};
</script>

<style lang="scss">
.ui-input-label {
  margin-bottom: 10px;
  display: block;
  font-family: "Poppins";
  font-weight: 500;
  color: #304251;
  font-size: 14px;
}
.ui-input-field {
  .v-input__slot {
    min-height: unset !important;
    box-shadow: unset !important;
    padding: 0 14px !important;
    border-radius: 19px;
    margin-bottom: 4px;
    border-color: #8e9c9c;

    input {
      font-family: "Source Sans Pro", sans-serif;
      font-weight: 400;
      font-size: 14px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
  &.v-input--is-disabled {
    color: "#979494";
    background: #f4f6f6;
    fieldset {
      border-color: #f4f6f6;
    }
  }
}
</style>

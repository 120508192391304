<template>
  <section>
    <span class="ui-select-span" v-if="label && !questionMark">{{
      label
    }}</span>
    <span class="ui-select-span-sm" v-if="smallLabel">{{ smallLabel }}</span>
    <section v-if="questionMark" class="question-mark">
      <span class="ui-select-span" v-if="label">{{ label }}</span>
      <v-icon
        @click="questionMarkClick()"
        class="ui-select-icon"
        color="#CA6D4F"
        size="20"
        >mdi-help-circle-outline</v-icon
      >
    </section>

    <v-select
      v-if="isPlaceholder"
      :rules="rules"
      class="ui-select"
      @change="onChange"
      :placeholder="placeholder"
      :items="items"
      :item-text="(i) => i && i.name"
      :item-value="(i) => i && i.id"
      :value="value"
      dense
      rounded
      outlined
      append-icon="mdi-chevron-down"
      :hide-details="hideDetail ? 'auto' : false"
    >
      <template slot="selection" slot-scope="{ item }">
        <span :style="!item || !item.id ? { color: 'rgb(118, 118, 118)' } : ''">
          {{ item && item.name }}
        </span>
      </template>
      <template slot="item" slot-scope="{ item }">
        <span :style="!item || !item.id ? { color: 'rgb(118, 118, 118)' } : ''">
          {{ item && item.name }}
        </span>
      </template>
    </v-select>
    <v-select
      v-else
      :rules="rules"
      class="ui-select"
      @change="onChange"
      :placeholder="placeholder"
      :items="items"
      :item-text="(i) => i && i.name"
      :item-value="(i) => i && i.id"
      :value="value"
      dense
      rounded
      outlined
      append-icon="mdi-chevron-down"
      :hide-details="hideDetail ? 'auto' : false"
    >
    </v-select>
  </section>
</template>

<script>
export default {
  props: [
    "isPlaceholder",
    "items",
    "value",
    "rules",
    "placeholder",
    "label",
    "smallLabel",
    "questionMark",
    "hideDetail",
  ],
  methods: {
    onChange(value) {
      this.$emit("onChange", value);
    },
    questionMarkClick() {
      this.$emit("questionMarkClick");
    },
  },
};
</script>

<style lang="scss">
.ui-select-span-sm {
  display: block;
  margin-bottom: 10px;
  font-family: "Poppins";
  font-weight: 500;
  color: #304251;
  font-size: 11px;
}
.question-mark {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .ui-select-span {
    margin-bottom: 0px;
  }
  .ui-select-icon {
    cursor: pointer;
  }
}
.ui-select-span {
  display: block;
  margin-bottom: 10px;
  font-family: "Poppins";
  font-weight: 500;
  color: #304251;
  font-size: 14px;
}
.ui-select {
  font-size: 14px !important;
  font-family: "Source Sans Pro", sans-serif;
  .v-input__slot {
    min-height: unset !important;
    box-shadow: unset !important;
    border-color: #8e9c9c;
    border-radius: 19px;
    height: 38px;
    padding: 0 14px !important;
    input {
      font-weight: 400;
      padding: 0px !important;
    }
  }
}
</style>
